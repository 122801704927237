import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`What is I'rab in Arabic Grammar?`}</h2>
    <p>{`The word I'rab comes from أعرب يعرب أعرب الإعراب`}</p>
    <ul>
      <li parentName="ul">{`In لغة it means الإظهار و الإبانة i.e. when you want to show something`}</li>
      <li parentName="ul">{`Technically (اصطلاحاً) it means`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`تغييرُ أواخِرِ الكَلِمِ لاختِلافِ العوامِلِ الداخِلةِ عليها لفظاً أو تقديراً`}</p>
        </blockquote>
      </li>
    </ul>
    <h2>{`Visible Changes (لفظاً)`}</h2>
    <p>{`Let's take the example of زيدٌ,`}<br parentName="p"></br>{`
`}{`جاء زيدٌ`}<br parentName="p"></br>{`
`}{`رأيت زيداً`}<br parentName="p"></br>{`
`}{`ذهبت إلى زيدٍ`}<br parentName="p"></br>{`
`}{`We observe that the end of زيدٌ changes لفظاً when used with different words e.g. جاء, رأيت etc. The words جاء, رأيت are called عوامل  `}</p>
    <h2>{`Invisible Changes (تقديراً)`}</h2>
    <p>{`Let's take the example of مصطفى,`}<br parentName="p"></br>{`
`}{`جاء مصطفى`}<br parentName="p"></br>{`
`}{`رأيت مصطفى`}<br parentName="p"></br>{`
`}{`ذهبت إلى مصطفى `}</p>
    <h2>{`Cases of I'rab (حالات الإعراب)`}</h2>
    <p>{`I'rab has four cases الرفع, النصب, الجر, الجزم. These cases can be found in الأسماء Or الأفعال.  `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Case`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`الرفع`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`الأسماء الأفعال`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`النصب`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`الأسماء الأفعال`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`الجر`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`الأسماء فقط`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`الجزم`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`الأفعال فقط`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "351px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "82.20858895705521%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACMElEQVQ4y5VSTU/bQBD1n++xp54QbYWq9lDaHqoKqWoE5ANUShLStEBCSAKhJMF2EufDjvfD9uvsOnGcqiBYabSzb2fevNlZA6kVRZHemQjRG3H0RwJ9R6A3ZOiSJRjtXIZrOctl/I/QHAuc3krU+wFqPYnWALhygIYZ6fMZ3dkT8XhCi4LPbzk6RLKTq+LFy/d49nwDuXIb10Og1hUYTMXTFJ53JZpWhEpzjK8HZ9jeOcRx3UbLBt2Jpyl0XImLPsdlX6Bth7iilm9IbXsQoUF4g/CJJx9PSB64CPTDMy4xMCdkY+0rTMhgEf8A4fJC7YkfUjITa6awf5WlfeM+MkkKBSlSe2IyxoJEYbSWqwkfIlOL+0pVhJCUcRYPQrD7SY0lsCIjVQsyTeKLpB3uc8LiuKX6NKkmROpN00FqKRXps/KDIFyddbxMT5UULpRMR+6q4gKbDGe63XQBjSk1SRGJqeMmmOF3rsFOSvCKRXi/qxrklgVfYaUyZuUiQsYgXResWiH8hPAixHCoJz7/9ZNyS3CPj8D+3BDhRR2nH7ex92oTZi4bt97rwtzNILv1Gvb+LkLPg3AcXH75jMKbLZTevYVPMapFM7uHzOYGap8+QLRbMOatJnqUfJfbh1spa+nCvINFgWY+S9V/IJjPIadTDA7ysAt5jA8L4LalCZ2j7+hkvmFMd4y6NUJB38KjNyAVKlFPV0qEboypVpcTVEp1nMKCIH5rlUMWzGZQXH8BgnvOnYbu1PMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "4 Cases of I'rab",
          "title": "4 Cases of I'rab",
          "src": "/static/fe7f2d3579b4c1ad702054168fe8c437/7c2a6/4-cases-of-Irab.png",
          "srcSet": ["/static/fe7f2d3579b4c1ad702054168fe8c437/222b7/4-cases-of-Irab.png 163w", "/static/fe7f2d3579b4c1ad702054168fe8c437/ff46a/4-cases-of-Irab.png 325w", "/static/fe7f2d3579b4c1ad702054168fe8c437/7c2a6/4-cases-of-Irab.png 351w"],
          "sizes": "(max-width: 351px) 100vw, 351px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`علامات الإعراب`}</h2>
    <p>{`It can be divided into two categories`}</p>
    <br />
    <p><strong parentName="p">{`علامات أصلية`}</strong>{`  `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Case`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`عَلامَة`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`الرفع`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`الضمة`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`النصب`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`الفتحة`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`الجر`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`الكسرة`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`الجزم`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`السكون`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p><strong parentName="p">{`علامات فرعية`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`الواو  `}</li>
      <li parentName="ul">{`الألف  `}</li>
      <li parentName="ul">{`النون  `}</li>
      <li parentName="ul">{`الياء  `}</li>
      <li parentName="ul">{`الفتحة  `}</li>
      <li parentName="ul">{`الكسرة  `}</li>
    </ul>
    <h2>{`Irab of Dual`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`إعراب المثنى`}</strong></p>
    <p>{`In Dual nouns, it is mabe Raf with ا and made Nasb and Jar with ي.  `}</p>
    <blockquote>
      <p parentName="blockquote">{`يرفع المثنى بالألف ويُنصَب ويُجَرّ بالياء`}</p>
    </blockquote>
    <p>{`Example`}</p>
    <ul>
      <li parentName="ul">{`جاء الرجل`}<strong parentName="li">{`ا`}</strong>{`ن`}</li>
      <li parentName="ul">{`رأيت الرجل`}<strong parentName="li">{`ي`}</strong>{`ن `}</li>
      <li parentName="ul">{`ممرت بالرجل`}<strong parentName="li">{`ي`}</strong>{`ن`}</li>
    </ul>
    <h2>{`Irab of Masculine Unbroken Plural`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`إعراب جمع المذكر السالم`}</strong>{`  `}</p>
    <br />
For Masculine Unbroken Plural, It is made Raf by و and Nasb, Jar by ي
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`يرفع      بالواو`}</li>
        <li parentName="ul">{`ينصب    بالياء`}</li>
        <li parentName="ul">{`يجر       بالياء`}</li>
      </ul>
    </blockquote>
    <p>{`Example`}</p>
    <ul>
      <li parentName="ul">{`جاء المسلم`}<strong parentName="li">{`و`}</strong>{`ن`}</li>
      <li parentName="ul">{`رأيت المسلم`}<strong parentName="li">{`ي`}</strong>{`ن`}</li>
      <li parentName="ul">{`مررت بالمسلم`}<strong parentName="li">{`ي`}</strong>{`ن`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`I'rab of جاء المسلمون`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`المسلمون`}</strong>{`
فاعل مرفوع وعلامة رفعه الواو نيابة عن الضمة لأنه جمع مذكر سالم`}</p>
    </blockquote>
    <br />
    <p>{`It is also said that the ن is in place of Tanween in dual and plural form, its badl min tanween but not in every case. Basically its Ijtihad of Ulema`}</p>
    <blockquote>
      <p parentName="blockquote">{`النون عوض عن التنوين`}</p>
    </blockquote>
    <br />
    <p>{`Example`}</p>
    <ul>
      <li parentName="ul">{`Singular: جاء رجلٌ, Dual: جاء الرجلان`}</li>
      <li parentName="ul">{`Singular جاء مسلمٌ, Plural: جاء المسلمون`}</li>
    </ul>
    <p>{`Also, ن of Masculine Unbroken Plural is with Fatha and ن of Dual is with Kasra`}</p>
    <blockquote>
      <p parentName="blockquote">{`نون جمع المذكر السالم مفتوحة او نون المثنى مكسورة`}</p>
    </blockquote>
    <br />
    <p>{`When Masculine Unbroken Plural and Dual is made idhafa its ن is dropped`}</p>
    <blockquote>
      <p parentName="blockquote">{`نون جمع المذكر السالم ونون المثنى تسقطان عند الإضافة`}</p>
    </blockquote>
    <br />
    <p>{`Example:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Plural`}</strong></p>
    <ul>
      <li parentName="ul">{`جاء مسلم`}<strong parentName="li">{`و`}</strong>{` مكة`}</li>
      <li parentName="ul">{`رأيت مسلم`}<strong parentName="li">{`ي`}</strong>{` مكة`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Dual`}</strong></p>
    <ul>
      <li parentName="ul">{`جاء طالب`}<strong parentName="li">{`ا`}</strong>{` العلمِ`}</li>
      <li parentName="ul">{`رأيت طالبَ`}<strong parentName="li">{`ي`}</strong>{` العلمِ`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Rules when made mudhaf with ي`}</strong></p>
    <ul>
      <li parentName="ul">{`هذان كتابان + ي becomes كِتاباي`}</li>
      <li parentName="ul">{`قرأت كِتَابَيْن + ي  becomes  كِتَابَيَّ`}</li>
      <li parentName="ul">{`جاء مُسْلِمُوْنَ + ي`}<br parentName="li"></br>
        {`مُسْلِمُوْ + ي`}<br parentName="li"></br>
        {`مُسْلِمِيْ + ي`}<br parentName="li"></br>
        {`مُسْلِمِيّ  `}</li>
    </ul>
    <h2>{`Irab of Feminine Unbroken Plural`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`إعراب جمع المؤنث السالم`}</strong></p>
    <br />
Irab of Feminine Unbroken Plura is made Raf with Damma, Nasb and Jar with Kasra
    <blockquote>
      <p parentName="blockquote">{`يرفع    بالضمة`}<br parentName="p"></br>{`
`}{`ينصب  بالكسرة`}<br parentName="p"></br>{`
`}{`يجر    بالكسرة  `}</p>
    </blockquote>
    <br />
    <p>{`Example:`}</p>
    <ul>
      <li parentName="ul">{`Raf: جاءت المسلما`}<strong parentName="li">{`تُ`}</strong></li>
      <li parentName="ul">{`Nasb: رأيت المسلما`}<strong parentName="li">{`تِ`}</strong>{` `}</li>
      <li parentName="ul">{`Jar: مررت بالمسلما`}<strong parentName="li">{`تِ`}</strong></li>
    </ul>
    <br />
    <p><strong parentName="p">{`I'rab of رأيت المسلماتِ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`المسلمات`}</strong>{`
مفعول به منصوب وعلامة نصبه الكسرة نيابة عن الفتحة لأنه جمع مؤنث سالم`}</p>
    </blockquote>
    <h2>{`I'rab of Masculine broken Plural`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`إعراب جمع التكسير`}</strong></p>
    <p>{`They are called broken because they are not made exactly from the Singular form by adding وي.`}<br parentName="p"></br>{`
`}{`ِExample:`}</p>
    <ul>
      <li parentName="ul">{`رَجُل and رِجَال`}</li>
      <li parentName="ul">{`رَبْعٌ and  رُبُوع`}</li>
    </ul>
    <br />
I'rab of Masculine broken Plural is just like I'rab of singular i.e.
    <blockquote>
      <p parentName="blockquote">{`يرفع    بالضمة`}<br parentName="p"></br>{`
`}{`ينصب   بالفتحة`}<br parentName="p"></br>{`
`}{`يجر     بالكسرة  `}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`Raf: جاء الرجا`}<strong parentName="li">{`لُ`}</strong></li>
      <li parentName="ul">{`Nasb: رأيت الرجا`}<strong parentName="li">{`لَ`}</strong></li>
      <li parentName="ul">{`Jar: مررت بالرجا`}<strong parentName="li">{`لِ`}</strong></li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`حالات الإعراب`}</strong></p>
    <ul>
      <li parentName="ul">{`الرفع بالضمة`}</li>
      <li parentName="ul">{`النصب بالفتحة`}</li>
      <li parentName="ul">{`الجر بالكسرة`}</li>
      <li parentName="ul">{`الجزم بالسكون`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`وخرج عن ذلك سبعة أبواب`}</strong></p>
    <ul>
      <li parentName="ul">{`الأسماء الستة`}</li>
      <li parentName="ul">{`المثنى`}</li>
      <li parentName="ul">{`جمع المذكر السالم`}</li>
      <li parentName="ul">{`جمع المؤنث السالم`}</li>
      <li parentName="ul">{`الممنوع من الصرف`}
        <ul parentName="li">
          <li parentName="ul">{`Here we use Damma for raf, fatha for nasb and kasra for Jar e.g. عرم، فاطمة`}</li>
        </ul>
      </li>
      <li parentName="ul">{`الأفعال الخمسة`}
        <ul parentName="li">
          <li parentName="ul">{`we use shubut nun for raf and hazf nun for Nasb and Jazm`}</li>
        </ul>
      </li>
      <li parentName="ul">{`الفعل المضارع المعتل الآخر`}
        <ul parentName="li">
          <li parentName="ul">{`Damma mukaddara e.g. يسعي Or يدعو`}</li>
          <li parentName="ul">{`Jazm => Hazf harf Illa`}</li>
          <li parentName="ul">{`Nasb => Fatha mukaddara for alif and fatha zahiara for و and ي`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Reference`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.qutoofacademy.com/",
        "rel": "nofollow noopener"
      }}>{`Qutoof Academy`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      